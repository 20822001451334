import omit from 'lodash/omit';
import { selectIsAuthenticated } from 'src/common/selectors/auth';
import { selectIsFord, selectTuneInUserSerial } from '../selectors/app';
import {
  selectUserAccountId,
  selectUserEmail,
  selectUsername,
} from '../selectors/me';
import mapMeResponse from './data/mapMeResponse';
import mapUserFavorites from './data/user/mapFavorites';
import mapUserResponse from './data/user/mapResponse';
import { addReportingMetadata } from './utils/addReportingMetadata';

export const FETCH_ME = 'FETCH_ME';
export const FETCH_ME_WITH_FAVORITES = 'FETCH_ME_WITH_FAVORITES';
export const FETCH_FAVORITES_FOLDER = 'FETCH_FAVORITES_FOLDER';
export const REORDER_FAVORITES = 'REORDER_FAVORITES';
export const MOVE_FAVORITE_TO_FOLDER = 'MOVE_FAVORITE_TO_FOLDER';
export const ADD_FOLDER = 'ADD_FOLDER';
export const SET_DEFAULT = 'SET_DEFAULT';
export const SAVE_ME = 'SAVE_ME';
export const FETCH_ME_DEVICES = 'FETCH_ME_DEVICES';
export const ADD_ME_DEVICE = 'ADD_ME_DEVICE';
export const DELETE_ME_DEVICE = 'DELETE_ME_DEVICE';
export const ADD_ME_CONSENT = 'ADD_ME_CONSENT';

const meTypeApiMap = {
  [FETCH_ME]: 'getMe',
  [FETCH_ME_WITH_FAVORITES]: 'getMeWithFavorites',
  [SAVE_ME]: 'saveMe',
  [FETCH_ME_DEVICES]: 'getMeDevices',
  [ADD_ME_DEVICE]: 'addMeDevice',
  [DELETE_ME_DEVICE]: 'deleteMeDevice',
  [ADD_ME_CONSENT]: 'addMeConsent',
};

function apiResponseTransform(response) {
  const { items: children, metadata } = response.data;
  const { seoInfo = {} } = metadata?.properties || {};

  addReportingMetadata(seoInfo)({ children });

  return {
    containerItems: children,
  };
}

export function selectFolder({ folder }) {
  const prefixedFolderId = `f${folder.folderId}`;

  return {
    type: FETCH_FAVORITES_FOLDER,
    api: {
      endpoint: ['profile', 'favoritesFolder'],
      args: [folder.contentId, prefixedFolderId],
      transform: apiResponseTransform,
    },
    meta: {
      folder,
    },
  };
}

export function reorderFavorites({ guideItem, fromIndex, toIndex, folder }) {
  return {
    type: REORDER_FAVORITES,
    api: {
      endpoint: ['favorites', 'order'],
      args: [
        {
          favoriteId: guideItem.actions.follow.favoriteId,
          index: toIndex + 1, // Favs API is 1-based
        },
      ],
    },
    meta: {
      guideItem,
      folder,
      fromIndex,
      toIndex,
    },
  };
}

export function moveFavoriteToFolder({
  guideItem,
  fromIndex,
  fromFolder,
  toFolder,
}) {
  return {
    type: MOVE_FAVORITE_TO_FOLDER,
    api: {
      endpoint: ['favorites', 'move'],
      args: [
        {
          favoriteId: guideItem.actions.editFavorite.favoriteId,
          folderId: toFolder.folderId,
        },
      ],
    },
    meta: {
      guideItem,
      fromIndex,
      fromFolder,
      toFolder,
    },
  };
}

export function addFolder({ name, userId }) {
  return {
    type: ADD_FOLDER,
    api: {
      endpoint: ['favorites', 'addFolder'],
      args: [
        {
          name,
          userId,
        },
      ],
      transform: (res) => ({
        folderId: res.data.body[0].guideId.substring(1),
      }),
    },
    meta: {
      name,
      userId,
    },
  };
}

export function setDefaultFolder({ folder }) {
  return {
    type: SET_DEFAULT,
    api: {
      endpoint: ['favorites', 'setDefaultFolder'],
      args: [{ folderId: folder.folderId }],
    },
    meta: { folder },
  };
}

function meEffect({ type, args, transform }) {
  return {
    type,
    api: {
      endpoint: ['profile', meTypeApiMap[type]],
      args,
      transform,
    },
    meta: {
      contentId: 'details',
    },
  };
}

function transformMeResponse(mapFunc, secondaryMapFunc) {
  return (response) => {
    if (response.auth && response.auth.isNotAuthenticated) {
      return {
        content: null,
      };
    }

    const mappedData = mapFunc(response.data);
    const otherData = secondaryMapFunc ? secondaryMapFunc(mappedData) : {};

    return {
      content: {
        ...omit(mappedData, 'containerItems'),
        ...otherData,
      },
    };
  };
}

export function fetchMeWithFavorites() {
  return (dispatch, getState) => {
    const state = getState();

    if (selectIsAuthenticated(state)) {
      return dispatch(
        meEffect({
          type: FETCH_ME_WITH_FAVORITES,
          transform: transformMeResponse(mapUserResponse, mapUserFavorites),
        }),
      );
    }

    return null;
  };
}

export function fetchMe(forceFetch = false) {
  return (dispatch, getState) => {
    const state = getState();

    if (
      forceFetch === true ||
      (selectIsAuthenticated(state) &&
        !selectIsFord(state) &&
        !selectUserAccountId(state))
    ) {
      return dispatch(
        meEffect({
          type: FETCH_ME,
        }),
      );
    }

    return null;
  };
}

export function refreshMe() {
  return (dispatch, getState) => {
    const state = getState();

    if (selectIsAuthenticated(state)) {
      return dispatch(
        meEffect({
          type: FETCH_ME,
        }),
      );
    }

    // need to return promise so we can wait for this to complete
    // and use a then
    return Promise.resolve();
  };
}

export function moveItemToNewFolder({ baseParams }) {
  return async (dispatch, getState) => {
    const res = await dispatch(addFolder(baseParams.addFolder));

    const {
      value: { folderId },
    } = res;
    const toFolder =
      getState().me.details.customFavoriteFoldersIndexed[folderId];
    const params = {
      ...baseParams.moveFavoriteToFolder,
      toFolder,
    };

    await dispatch(moveFavoriteToFolder(params));
  };
}

export function saveMe({ name, email, isFollowingListPublic }) {
  return meEffect({
    type: SAVE_ME,
    args: [{ name, email, isFollowingListPublic }],
    transform: transformMeResponse(mapMeResponse),
  });
}

export function fetchMeDevices() {
  return (dispatch, getState) => {
    const state = getState();

    if (selectIsAuthenticated(state)) {
      return dispatch({
        type: FETCH_ME_DEVICES,
        api: {
          endpoint: ['profile', meTypeApiMap[FETCH_ME_DEVICES]],
          transform: (response) => ({ content: { list: response.data } }),
          // Setting this so fetch errors on the server won't result in the error page showing.
          // Client side will work as normal.
          registerAppError: false,
        },
        meta: {
          contentId: 'devices',
        },
      });
    }

    return null;
  };
}

export function addMeDevice(registrationCode) {
  return {
    type: ADD_ME_DEVICE,
    api: {
      endpoint: ['profile', meTypeApiMap[ADD_ME_DEVICE]],
      args: [registrationCode],
      transform: (response) => ({ content: response.data }),
    },
  };
}

export const addMeConsent =
  ({ type, version }) =>
  (dispatch, getState) => {
    const state = getState();

    const consent = {
      userId: selectTuneInUserSerial(state),
      userName: selectUsername(state),
      userEmail: selectUserEmail(state),
      agreementName: type,
      agreementVersion: version,
      acceptanceDate: new Date().toISOString(),
    };

    return dispatch(
      meEffect({
        type: ADD_ME_CONSENT,
        args: [consent],
      }),
    );
  };

export function deleteMeDevice(id) {
  return {
    type: DELETE_ME_DEVICE,
    api: {
      endpoint: ['profile', meTypeApiMap[DELETE_ME_DEVICE]],
      args: [id],
    },
    meta: {
      id,
    },
  };
}
